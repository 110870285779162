import { CommonButton, CommonButtonType } from "../../../_common-client/UI/CommonButton"
import { usePageNavigation } from "../../../_common-client/PageStack/PageNavigation"
import { LoginLink } from "../PageURL/LoginLink"
import { useEffect, useRef, useState } from "react"
import { Button, Form } from "react-bootstrap"

// const LocalizedPage = {
//     benefit1: {
//         title: "Personalized learning plan",
//         example1: "Learn what you really need in life",
//         example2: "Expand your vocabulary",
//         price: 40,
//     },
//     benefit2: {
//         title: "Support from an accountability partner",
//         example1: "Stay motivated",
//         example2: "Get an answer to any question",
//         price: 40,
//     },
//     benefit3: {
//         title: "Regular feedback",
//         example1: "Maintain a good pace of learning",
//         example2: "Adjust the learning plan according to current needs",
//         price: 30,
//     },
//     benefit4: {
//         title: "Effective exercises",
//         example1: "Learn grammar automatically by practicing",
//         example2: "Feel real progress in 2 weeks",
//         price: 40,
//     },
//     benefit5: {
//         title: "Improve all language skills needed to become fluent",
//         example1: "Get rid of the fear of speaking",
//         example2: "Build confidence in using English",
//         price: 50,
//     },
//     benefit6: {
//         title: "Learn at any time convenient for you",
//         example1: "No lesson time limit",
//         example2: "No pressure when you need to study",
//         price: 30,
//     },
// }

// const allBenefitsValue = () => {
//     var result = 0
//     result += LocalizedPage.benefit1.price
//     result += LocalizedPage.benefit2.price
//     result += LocalizedPage.benefit3.price
//     result += LocalizedPage.benefit4.price
//     result += LocalizedPage.benefit5.price
//     result += LocalizedPage.benefit6.price
//     return result
// }

export const RootPageView: React.FC<{}> = (_) => {
    const navigation = usePageNavigation()
    // const [currentStep, setCurrentStepInternal] = useState(0)
    // const [isOtherCheckboxSelected, setIsOtherCheckboxSelected] = useState(false)

    // const setCurrentStep = (n: number) => {
    //     setCurrentStepInternal(n)
    //     setIsOtherCheckboxSelected(false)
    // }

    // const dreamOutcomeStep = 1
    // const acknowledgeChallengeStep = 21
    // const noNeedToLearnEnglishStep = 22
    // const previousBadExperienceStep = 31
    // const onlyPositiveExperienceStep = 32
    // const regularCoursesInefficientStep = 41
    // const ourValuePropositionStep = 51
    // const ourPriceStep = 61
    // const howToSignupStep = 71

    // const dreamOutcomeStepView = () => {
    //     return <>
    //         <p className="h3" style={{ fontWeight: "normal" }}>Do you want to <b>improve your English</b> to unlock new opportunities in your life?</p>
    //         <p />
    //         <div className="h4" style={{ fontWeight: "normal" }}>
    //             <ul>
    //                 <li>Promotion and career growth</li>
    //                 <li>High-paying work opportunities in international companies</li>
    //                 <li>Deeper connections with colleagues and friends</li>
    //             </ul>
    //         </div>
    //         <p />
    //         <div className="h5" style={{ fontWeight: "normal" }}>
    //             All of this requires effective communication and is impossible without fluency in English.
    //         </div>
    //         <p />
    //         <div style={{ display: "flex", gap: "10px", flexDirection: "row" }}>
    //             <div>
    //                 <CommonButton type={CommonButtonType.outline} handler={() => { setCurrentStep(acknowledgeChallengeStep) }} >
    //                     &nbsp; &nbsp; Yes &nbsp; &nbsp;
    //                 </CommonButton>
    //             </div>
    //             <div>
    //                 <CommonButton type={CommonButtonType.outline} handler={() => { setCurrentStep(noNeedToLearnEnglishStep) }} >
    //                     &nbsp; &nbsp; No &nbsp; &nbsp;
    //                 </CommonButton>
    //             </div>
    //         </div>
    //     </>
    // }

    // const acknowledgeChallengeStepView = () => {
    //     return <>
    //         <p className="h4" style={{ fontWeight: "normal" }}>Learning a language is <b>one of the most challenging things you will ever do</b>.</p>
    //         <p />
    //         <p>It takes <b>time</b> and <b>persistence</b> to become fluent in English. This path is rarely easy.</p>
    //         <p />
    //         Have you had a bad experience learning English before?
    //         <p />
    //         <div style={{ display: "flex", gap: "10px", flexDirection: "row" }}>
    //             <div>
    //                 <CommonButton type={CommonButtonType.outline} handler={() => { setCurrentStep(previousBadExperienceStep) }} >
    //                     &nbsp; &nbsp; Yes &nbsp; &nbsp;
    //                 </CommonButton>
    //             </div>
    //             <div>
    //                 <CommonButton type={CommonButtonType.outline} handler={() => { setCurrentStep(onlyPositiveExperienceStep) }} >
    //                     &nbsp; &nbsp; No &nbsp; &nbsp; 
    //                 </CommonButton>
    //             </div>
    //         </div>
    //     </>
    // }

    // const noNeedToLearnEnglishStepView = () => {
    //     return <>
    //         <p className="h4" style={{ fontWeight: "normal" }}>Inomova is an <b>English learning school</b>.</p>
    //         <p />
    //         <p >If you don't want to improve your English, we have nothing to offer you.</p>
    //         <p />
    //         <div style={{ display: "flex", gap: "10px", flexDirection: "row" }}>
    //             <div>
    //                 <CommonButton type={CommonButtonType.outline} handler={() => { setCurrentStep(ourValuePropositionStep) }} >
    //                     Could you tell me more about the school?
    //                 </CommonButton>
    //             </div>
    //         </div>
    //     </>
    // }

    // const previousBadExperienceStepView = () => {
    //     return <>
    //         <p className="h4">What experience have you had?</p>
    //         <p />
    //         <Form.Check type="checkbox" id="check1" label="Unrealistic promises" />
    //         <Form.Check type="checkbox" id="check2" label="Non-personalized curriculum" />
    //         <Form.Check type="checkbox" id="check3" label="No actual progress" />
    //         <Form.Check type="checkbox" id="check4" label="No personal feedback" />
    //         <Form.Check type="checkbox" id="check5" label="Tons of grammar rules" />
    //         <Form.Check type="checkbox" id="check6" label="Forgetting vocabulary" />
    //         <Form.Check type="checkbox" id="check7" label="Fear of speaking and writing" />
    //         <Form.Check type="checkbox" id="check8" label="Other" onChange={(e) => { setIsOtherCheckboxSelected(e.target.checked) }} />
    //         <Form.Control hidden={!isOtherCheckboxSelected} placeholder="Please provide details" style={{marginLeft: "20px", maxWidth: "300px"}} />
    //         <p />
    //         <div style={{ display: "flex", gap: "10px", flexDirection: "row" }}>
    //             <div>
    //                 <CommonButton type={CommonButtonType.outline} handler={() => { setCurrentStep(regularCoursesInefficientStep) }} >
    //                     &nbsp; &nbsp; Continue &nbsp; &nbsp;
    //                 </CommonButton>
    //             </div>
    //         </div>
    //     </>
    // }

    // const onlyPositiveExperienceStepView = () => {
    //     return <>
    //         <p className="h4" style={{ fontWeight: "normal" }}>That's awesome!</p>
    //         <p />
    //         <p>You'll love our accountability partner program and exercises that will take you to the next level.</p>
    //         <p />
    //         <div style={{ display: "flex", gap: "10px", flexDirection: "row" }}>
    //             <div>
    //                 <CommonButton type={CommonButtonType.outline} handler={() => { setCurrentStep(ourValuePropositionStep) }} >
    //                     Could you tell me more?
    //                 </CommonButton>
    //             </div>
    //         </div>
    //     </>
    // }

    // const regularCoursesInefficientStepView = () => {
    //     return <>
    //         <p className="h4" style={{ fontWeight: "normal" }}>Regular courses are <b>inefficient</b> and don’t bring you any closer to English fluency.</p>
    //         <p />
    //         <p>That’s why we focus on being <b>the best accountability partner</b>, providing a <b>personalized curriculum</b> and <b>support</b> for every student.</p>
    //         <p />
    //         <div style={{ display: "flex", gap: "10px", flexDirection: "row" }}>
    //             <div>
    //                 <CommonButton type={CommonButtonType.outline} handler={() => { setCurrentStep(ourValuePropositionStep) }} >
    //                     Could you tell me more?
    //                 </CommonButton>
    //             </div>
    //         </div>
    //     </>
    // }

    // const ourValuePropositionStepView = () => {
    //     return <>
    //         <p className="h3" style={{ marginBottom: "0px" }}>We provide:</p>
    //         <ul>
    //             <li>{LocalizedPage.benefit1.title}
    //                 <ul>
    //                     <li>{LocalizedPage.benefit1.example1}</li>
    //                     <li>{LocalizedPage.benefit1.example2}</li>
    //                 </ul>
    //             </li>
    //             <li>{LocalizedPage.benefit2.title}
    //                 <ul>
    //                     <li>{LocalizedPage.benefit2.example1}</li>
    //                     <li>{LocalizedPage.benefit2.example2}</li>
    //                 </ul>
    //             </li>
    //             <li>{LocalizedPage.benefit3.title}
    //                 <ul>
    //                     <li>{LocalizedPage.benefit3.example1}</li>
    //                     <li>{LocalizedPage.benefit3.example2}</li>
    //                 </ul>
    //             </li>
    //             <li>{LocalizedPage.benefit4.title}
    //                 <ul>
    //                     <li>{LocalizedPage.benefit4.example1}</li>
    //                     <li>{LocalizedPage.benefit4.example2}</li>
    //                 </ul>
    //             </li>
    //             <li>{LocalizedPage.benefit5.title}
    //                 <ul>
    //                     <li>{LocalizedPage.benefit5.example1}</li>
    //                     <li>{LocalizedPage.benefit5.example2}</li>
    //                 </ul>
    //             </li>
    //             <li>{LocalizedPage.benefit6.title}
    //                 <ul>
    //                     <li>{LocalizedPage.benefit6.example1}</li>
    //                     <li>{LocalizedPage.benefit6.example2}</li>
    //                 </ul>
    //             </li>
    //         </ul>
    //         <p />
    //         <p className="h3" style={{ marginBottom: "0px" }}>Our expectations from students:</p>
    //         <ul>
    //             <li>Studying 5 days a week</li>
    //         </ul>
    //         <p />
    //         <p>We will work hard to support you, but you also have to put in effort.<br/>
    //         We cannot learn English for you.</p>
    //         <p />
    //         <div style={{ display: "flex", gap: "10px", flexDirection: "row" }}>
    //             <div>
    //                 <CommonButton type={CommonButtonType.outline} handler={() => { setCurrentStep(ourPriceStep) }} >
    //                     What's the price?
    //                 </CommonButton>
    //             </div>
    //         </div>
    //     </>
    // }

    // const ourPriceStepView = () => {
    //     return <>
    //         <p className="h4">We provide:</p>
    //         <ul>
    //             <li>{LocalizedPage.benefit1.title} (€{LocalizedPage.benefit1.price} value)
    //                 <ul>
    //                     <li>{LocalizedPage.benefit1.example1}</li>
    //                     <li>{LocalizedPage.benefit1.example2}</li>
    //                 </ul>
    //             </li>
    //             <li>{LocalizedPage.benefit2.title} (€{LocalizedPage.benefit2.price} value)
    //                 <ul>
    //                     <li>{LocalizedPage.benefit2.example1}</li>
    //                     <li>{LocalizedPage.benefit2.example2}</li>
    //                 </ul>
    //             </li>
    //             <li>{LocalizedPage.benefit3.title} (€{LocalizedPage.benefit3.price} value)
    //                 <ul>
    //                     <li>{LocalizedPage.benefit3.example1}</li>
    //                     <li>{LocalizedPage.benefit3.example2}</li>
    //                 </ul>
    //             </li>
    //             <li>{LocalizedPage.benefit4.title} (€{LocalizedPage.benefit4.price} value)
    //                 <ul>
    //                     <li>{LocalizedPage.benefit4.example1}</li>
    //                     <li>{LocalizedPage.benefit4.example2}</li>
    //                 </ul>
    //             </li>
    //             <li>{LocalizedPage.benefit5.title} (€{LocalizedPage.benefit5.price} value)
    //                 <ul>
    //                     <li>{LocalizedPage.benefit5.example1}</li>
    //                     <li>{LocalizedPage.benefit5.example2}</li>
    //                 </ul>
    //             </li>
    //             <li>{LocalizedPage.benefit6.title} (€{LocalizedPage.benefit6.price} value)
    //                 <ul>
    //                     <li>{LocalizedPage.benefit6.example1}</li>
    //                     <li>{LocalizedPage.benefit6.example2}</li>
    //                 </ul>
    //             </li>
    //         </ul>
    //         <p />
    //         <p >This is €{allBenefitsValue() * 20} of received value per month, if you attend 20 lessons.</p>
    //         <p className="h4" style={{ fontWeight: "normal" }}>Our price - <b>€50</b> per month.</p>
    //         <p />
    //         <div style={{ display: "flex", gap: "10px", flexDirection: "row" }}>
    //             <div>
    //                 <CommonButton type={CommonButtonType.outline} handler={() => { setCurrentStep(howToSignupStep) }} >
    //                     How to subscribe?
    //                 </CommonButton>
    //             </div>
    //         </div>
    //     </>
    // }

    // const howToSignupStepView = () => {
    //     return <>
    //         <p className="h4">Next steps are:</p>
    //         <ol>
    //             <li>To sign up, use "Continue with Google" button.</li>
    //             <li>We will send you a welcome email with a few more questions to get to know you better.</li>
    //             <li>Together, we will identify areas for improvement and vocabulary to learn.</li>
    //             <li>Next, we will send you instructions on how to subscribe.</li>
    //             <li>We will support you on a regular basis to maintain a good pace of learning and adjust the curriculum to meet your needs.</li>
    //         </ol>
    //         <p />
    //         <CommonButton type={CommonButtonType.outline} handler={() => { navigation.toExternalDirectURL(LoginLink) }} >
    //             Continue with Google
    //         </CommonButton>
    //     </>
    // }

    // const displayStep = () => {
    //     if (currentStep === dreamOutcomeStep) {
    //         return dreamOutcomeStepView()
    //     }
    //     if (currentStep === acknowledgeChallengeStep) {
    //         return acknowledgeChallengeStepView()
    //     }
    //     if (currentStep === noNeedToLearnEnglishStep) {
    //         return noNeedToLearnEnglishStepView()
    //     }
    //     if (currentStep === previousBadExperienceStep) {
    //         return previousBadExperienceStepView()
    //     }
    //     if (currentStep === onlyPositiveExperienceStep) {
    //         return onlyPositiveExperienceStepView()
    //     }
    //     if (currentStep === regularCoursesInefficientStep) {
    //         return regularCoursesInefficientStepView()
    //     }
    //     if (currentStep === ourValuePropositionStep) {
    //         return ourValuePropositionStepView()
    //     }
    //     if (currentStep === ourPriceStep) {
    //         return ourPriceStepView()
    //     }
    //     if (currentStep === howToSignupStep) {
    //         return howToSignupStepView()
    //     }
    //     return dreamOutcomeStepView()
    // }

    const canvasRef = useRef<HTMLCanvasElement>(null);

    useEffect(() => {
        if (!canvasRef.current) {
            return
        }

        const canvas = canvasRef.current
        const ctx = canvas.getContext('2d')
        if (!ctx) {
            return
        }

        const canvasRect = canvas.getBoundingClientRect()
        const startRect = document.getElementById("start")?.getBoundingClientRect()
        if(!startRect) { return }
        
        const endRect = document.getElementById("end")?.getBoundingClientRect()
        if(!endRect) { return }

        const startX = startRect.right - canvasRect.left
        const startY = (startRect.top + startRect.bottom) * 0.5 - canvasRect.top

        const endX = endRect.right - canvasRect.left
        const endY = (endRect.top + endRect.bottom) * 0.5 - canvasRect.top

        ctx.setTransform(1, 0, 0, 1, 0, 0)
        ctx.scale(4, 4)
        ctx.clearRect(0, 0, canvas.width, canvas.height)
        
        ctx.lineWidth = 2
        ctx.lineCap = "round"
        ctx.beginPath()
        ctx.moveTo(startX + 15, startY)
        ctx.bezierCurveTo(
            startX + 85, startY - 50,
            startX + 50, endY,
            endX + 20, endY
        )
        ctx.stroke()

        ctx.beginPath()
        ctx.moveTo(endX + 8, endY)
        ctx.lineTo(endX + 20, endY+6)
        ctx.lineTo(endX + 20, endY-6)
        ctx.lineTo(endX + 8, endY)
        ctx.fill()

    }, [])

    return <div style={{ flexGrow: 1, display: "flex", flexDirection: "column" }}>
        <div style={{ flexGrow: 1, display: "flex", alignItems: "center" }}>
            <div style={{ flex: 1 }}>
                <center>
                    <h1 style={{ marginBottom: 0 }}><b>inomova</b></h1>
                    <p style={{ fontSize: "2em", marginTop: 0, marginBottom: "50px" }}>Learn English by practicing</p>
                </center>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", flexBasis: "auto", position: "relative" }}>
                    <div style={{display: "flex", flexDirection: "column", fontSize: "1.25em", gap: "10px"}}>
                        <div><span id="end">Read</span></div>
                        <div>Write</div>
                        <div>Listen</div>
                        <div>Speak</div>
                        <div><span id="start" >Repeat</span></div>
                    </div>
                    <canvas id="canva" ref={canvasRef} width="1200px" height="1200px" style={{ position: "absolute", width: "300px", height: "300px", zIndex: -1000, }}></canvas>
                </div>
                <center>
                    <p style={{ marginTop: "50px" }} />
                    <CommonButton type={CommonButtonType.fill} handler={() => { navigation.toExternalDirectURL(LoginLink) }} >
                        Continue with Google
                    </CommonButton>
                </center>
            </div>
        </div>


        {/* <div style={{ flexGrow: 1, display: "flex", alignItems: "center" }}>
            <div>
                <hr style={{ margin: "20px", opacity: 0 }} />
                {displayStep()}
                <hr style={{ margin: "20px", opacity: 0 }} />
            </div>
        </div>
        <div style={{ fontSize: "10px", textAlign: "center" }}>
            <Button
                size="sm"
                variant="link"
                onClick={() => { setCurrentStep(ourValuePropositionStep) }}
                style={{ fontSize: "10px", color: "#333333", textDecoration: "none" }}
            >Value</Button>
            |
            <Button
                size="sm"
                variant="link"
                onClick={() => { setCurrentStep(ourPriceStep) }}
                style={{ fontSize: "10px", color: "#333333", textDecoration: "none" }}
            >Pricing</Button>
            |
            <Button
                size="sm"
                variant="link"
                onClick={() => { setCurrentStep(howToSignupStep) }}
                style={{ fontSize: "10px", color: "#333333", textDecoration: "none" }}
            >Sign up</Button>
        </div> */}
    </div>
}

