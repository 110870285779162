import { useLocation, useNavigate } from "react-router-dom"
import { Page, PageNoParams } from "./PageURL"

export type PageNavigation = {
    getURL: (page: Page<PageNoParams, PageNoParams>) => string,
    toPage: (page: Page<PageNoParams, PageNoParams>) => void,
    getURLWithParams: <T, S>(page: Page<T, S>, params: T & S) => string,
    toPageWithParams: <T, S>(page: Page<T, S>, params: T & S) => void,
    toPreviousPage: () => void,
    toInternalDirectURL: (url: string) => void,
    toExternalDirectURL: (url: string) => void,
    openInNewTab: (url: string) => void,
    getCurrentURL: () => string,
    reloadCurrentPage: () => void,
}

export const usePageNavigation = (): PageNavigation => {
    const navigate = useNavigate()
    const location = useLocation()
    return {
        getURL: (page: Page<PageNoParams, PageNoParams>) => {
            return page.url({}).url
        },
        toPage: (page: Page<PageNoParams, PageNoParams>) => {
            const pageURL = page.url({}).url
            navigate(pageURL)
        },
        getURLWithParams: <T, S>(page: Page<T, S>, params: T & S) => {
            return page.url(params).url
        },
        toPageWithParams: <T, S>(page: Page<T, S>, params: T & S) => {
            const pageURL = page.url(params).url
            navigate(pageURL)
        },
        toPreviousPage: () => {
            navigate(-1)
        },
        toInternalDirectURL: (url: string) => {
            navigate(url)
        },
        toExternalDirectURL: (url: string) => {
            window.location.assign(url)
        },
        openInNewTab: (url: string) => {
            window.open(url, "_blank")
        },
        getCurrentURL: () => {
            return location.pathname + location.search
        },
        reloadCurrentPage: () => {
            window.location.reload()
        },
    }
}